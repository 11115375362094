<script lang="ts" setup>
const appUserDataStore = useAppUserData();
const { isUserAdmin, isUserSystemAdmin, hasPermission } =
  storeToRefs(appUserDataStore);

const tabs = ref<Tabs>([
  {
    label: "General",
    key: "/settings",
  },
]);

onMounted(() => {
  if (
    hasPermission.value("manage-app-data", {
      allowSysAdmin: true,
    })
  ) {
    tabs.value.push({
      label: "App Data",
      key: "/settings/app-data",
    });
  }

  if (
    hasPermission.value("manage-checklists", {
      allowSysAdmin: true,
    })
  ) {
    tabs.value.push({
      label: "Checklists",
      key: "/settings/checklists",
    });
  }

  if (
    hasPermission.value("view-reports", {
      allowSysAdmin: true,
    })
  ) {
    tabs.value.push({
      label: "Reports",
      key: "/settings/reports",
    });
  }
});

const onTabChange = (newKey: any) => {
  navigateTo(newKey, { replace: true });
};
</script>

<template>
  <NuxtLayout name="scaffold">
    <template #app-bar-title>
      <span class="text-h5"> Settings </span>
    </template>
    <div class="flex flex-col h-full">
      <div>
        <BaseTabBar
          v-if="tabs.length > 1"
          :tabs="tabs"
          :model-value="$route.path"
          @update:model-value="onTabChange"
        />
      </div>
      <div class="grow min-h-0 overflow-auto">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>

<style scoped></style>
